import { useState } from "react"


import { IconCloud, IconEdit, IconEllipsis, IconPlay, IconTrash, Modal, TextInput } from "@zipou/front_tools"
import { Cluster, ClusterConfigInput, ClusterInput } from "model"
import { ServerFirewallEdit } from "components/backoffice/HOSTING/Server/Firewall/ServerFirewallEdit"
import { ClusterConfigForm } from "../Config/Form/ClusterConfigForm"
import { ServerBadge } from "components/backoffice/HOSTING/Server/Badge/ServerBadge"

type ClusterFormProps = {
  cluster: ClusterInput,
  onChange: (cluster: ClusterInput) => void
  errors?: any,
}

export const ClusterForm = ({ cluster, errors, onChange }: ClusterFormProps) => {

  const [focusFirewall, updateFocusFirewall] = useState<string>("")
  const [focusClusterConfig, updateFocusClusterConfig] = useState<string>()


  const onClusterConfigDelete = (clusterConfigId: string) => {
    onChange({
      ...cluster,
      ClusterConfig: cluster?.ClusterConfig?.filter((el) => el.id !== clusterConfigId)
    })
  }


  return <div className="cluster-form-container">

    <Modal display={!!focusClusterConfig} onClose={() => updateFocusClusterConfig(undefined)} title={"Config"}>
      <ClusterConfigForm clusterConfig={cluster?.ClusterConfig?.find(el => el.id === focusClusterConfig) as ClusterConfigInput} cluster={cluster} onChange={(clusterConfig) => {
        onChange({
          ...cluster,
          ClusterConfig: cluster?.ClusterConfig?.map((c, i) => c?.id === focusClusterConfig ? clusterConfig : c)
        })
      }} />
    </Modal>

    <Modal display={!!focusFirewall} size='xl' title={"Edition du Firewall"} centered={false} onClose={() => updateFocusFirewall("")}>
      <ServerFirewallEdit serverId={focusFirewall} />
    </Modal>

    <h2 className="mb-4 mt-4">Paramètres</h2>

    <div className="input-group">
      <span className="input-group-text">Modifier le mot de passe ?</span>
      <div className="form-control" style={{ height: "100%" }}>
        <button className="btn btn-sm btn-warning" disabled={!!cluster?.passwordUpdate} onClick={() => {
          onChange({
            ...cluster,
            passwordUpdate: true,
          })
        }}>Modifier</button>
      </div>
    </div>

    <TextInput rightContent={<span className="badge badge-warning">.box4b.net</span>} value={cluster?.name || ""} label="Nom" id="name" errors={errors} onChange={(value) => {
      onChange({
        ...cluster,
        name: value,
      })
    }} />



    <div>
      <h2 className="mb-4 mt-4">Noeuds</h2>
      <table className="table table-striped table-bordered">
        <thead>
          <tr className="bg-dark text-white">
            <td className="bg-dark text-white">Nom</td>
            <td className="bg-dark text-white">Serveur</td>
            <td className="bg-dark text-white">Roles</td>
            <td className="bg-dark text-white">Actions</td>
          </tr>
        </thead>
        <tbody>
          {cluster?.ClusterConfig?.map((clusterConfig) => {
            return <tr key={`clusterConfig_${clusterConfig?.id}`}>
              <td>{clusterConfig?.name}.{cluster?.name}</td>
              <td><ServerBadge id={clusterConfig?.serverId || ""} /></td>
              <td>

                <table className="table table-bordered table-striped">
                  <tbody>
                    {clusterConfig?.ClusterRoleConfig && [...clusterConfig?.ClusterRoleConfig]?.sort((el1: any, el2: any) => el1?.name.localeCompare(el2?.name)).map((clusterRoleConfig) => {
                      return <tr key={clusterRoleConfig?.clusterRoleId}>
                        <td>
                          <span className={"badge badge-pill badge-dark ml-1"}>
                            {clusterRoleConfig?.name}
                          </span>
                        </td>
                      </tr>
                    })}
                  </tbody>

                </table>

              </td>
              <td>
                <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusClusterConfig(clusterConfig?.id || undefined)}>
                  <IconEdit />
                </button>
                <button className="btn btn-sm btn-info ml-1" onClick={() => updateFocusFirewall(clusterConfig.serverId || "")}>
                  <IconCloud />
                </button>
                <button className="btn btn-sm btn-warning ml-1" onClick={() => onClusterConfigDelete(clusterConfig?.id || "")} >
                  <IconTrash />
                </button>
              </td>
            </tr>
          })}
        </tbody>
      </table>
      <div className="actions">
        <button className="btn btn-secondary btn-sm" onClick={() => {
          const newId = `${new Date().getTime()}`
          updateFocusClusterConfig(newId)
          onChange({
            ...cluster,
            ClusterConfig: [
              ...(cluster?.ClusterConfig || []),
              {
                id: newId,
                name: "",
                serverId: "",
              }
            ]
          })
        }}>Ajouter</button>
      </div>
    </div>
  </div>
}