import { useQuery } from "@apollo/client"
import { IconBookOpen, IconEdit, Modal } from "@zipou/front_tools"
import { PhoneConfig } from "model"
import { useState } from "react"
import phoneConfigListGql from "graphql/HOSTING/Phone/Config/query/phoneConfigList.gql"

import { Link } from "react-router-dom"
import { URL_PHONE_MODULE_DIRECTORY_DISPLAY, URL_PHONE_MODULE_DISPLAY } from "static/constant/backoffice/url"
import { PhoneConfigAdd } from "../Add/PhoneConfigAdd"

export const PhoneConfigListByUser = () => {

  const { data } = useQuery<{ list: PhoneConfig[] }>(phoneConfigListGql)

  const [focusEdit, updateFocusEdit] = useState<PhoneConfig | null>()

  const phoneConfigList = data?.list
  return <div>
    <h1>Liste des configurations Téléphonie</h1>
    <table className="table table-striped table-bordered">
      <thead>
        <tr className="bg-dark text-white">
          <td className="bg-dark text-white">Identifiant</td>
          <td className="bg-dark text-white">Actions</td>
        </tr>
      </thead>
      <tbody>
        {phoneConfigList?.map(phoneConfig => <tr key={`phoneConfig_${phoneConfig?.id}`}>
          <td>{phoneConfig?.name || phoneConfig?.id}</td>
          <td>
            <Link to={`/manager${URL_PHONE_MODULE_DISPLAY.replace(":id", phoneConfig?.id).replace(":token", phoneConfig?.token || "")}`}>
              <button className="btn btn-sm btn-warning ml-1">
                <IconEdit />
              </button>
            </Link>
            <Link to={`/manager${URL_PHONE_MODULE_DIRECTORY_DISPLAY.replace(":id", phoneConfig?.id).replace(":token", phoneConfig?.token || "")}`}>
              <button className="btn btn-sm btn-warning ml-1">
                <span className="" style={{ color: "white", fontSize: 6 }}>
                  <IconBookOpen />
                </span>
              </button>
            </Link>
          </td>
        </tr>)}
      </tbody>
    </table>

  </div >
}