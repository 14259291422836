import { useState } from "react"
import { PhoneConfigForm } from "../Form/PhoneConfigForm"
import { PhoneConfigEditInput, PhoneConfigInput } from "model"
import { useMutation } from "@apollo/client"
import phoneConfigAddGql from "graphql/HOSTING/Phone/Config/mutation/phoneConfigAdd.gql"
import { formatErrorResponseForJoi } from "helpers/Apollo"

type PhoneConfigAddProps = {
  onDone?: () => void,
}

export const PhoneConfigAdd = ({ onDone }: PhoneConfigAddProps) => {

  const [phoneConfig, updatePhoneConfig] = useState<PhoneConfigEditInput>()
  const [errors, updateErrors] = useState<any>()

  const [mutate] = useMutation(phoneConfigAddGql)

  const onSave = () => {
    mutate({
      variables: {
        input: phoneConfig,
      }
    })
      .then(() => {
        onDone && onDone()
        console.log("OK")
      })
      .catch((e) => {
        const eFormatted = formatErrorResponseForJoi(e)
        updateErrors(e)
        console.log("EEEEE", e)
        console.log("eFormatted", eFormatted)
      })
  }

  console.log("errors", errors)

  return <div>
    {!!errors && <div className="alert alert-danger">
      <h1>Erreur</h1>
    </div>}
    <PhoneConfigForm phoneConfigInput={phoneConfig as PhoneConfigInput} onChange={(pc) => updatePhoneConfig(pc)} errors={errors} />
    <div className="mt-1">
      <button className="btn btn-sm btn-dark ml-1" onClick={() => onSave()}>Enregistrer</button>
      <button className="btn btn-sm btn-danger ml-1" onClick={() => onDone && onDone()}>Annuler</button>
    </div>
  </div>
}