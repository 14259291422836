import { useQuery } from "@apollo/client"
import { SelectInput, TextInput } from "@zipou/front_tools"
import hostingConfigListGql from "graphql/HOSTING/Config/query/hostingConfigList.gql"
import { HostingConfig, PhoneConfigEditInput, PhoneConfigInput } from "model"

type PhoneConfigFormProps = {
  phoneConfigInput: PhoneConfigEditInput,
  onChange: (phoneConfigInput: PhoneConfigEditInput) => void,
  errors: any,
}

export const PhoneConfigForm = ({ phoneConfigInput, onChange, errors }: PhoneConfigFormProps) => {

  const { data } = useQuery<{ list: HostingConfig[] }>(hostingConfigListGql)

  const hostingConfigList = data?.list

  return <>

    <SelectInput value={phoneConfigInput?.hostingConfigId || ""} label="Config" choiceList={hostingConfigList?.map(config => ({ id: config?.id, label: config?.name || config?.id })) || []} id={"hostingConfigId"} errors={errors} onChange={(v) => {
      onChange({
        ...phoneConfigInput,
        hostingConfigId: v
      })
    }} />

    <TextInput id="name" errors={errors} label="Nom" value={phoneConfigInput?.name || ""} onChange={(v) => {
      onChange({
        ...phoneConfigInput,
        name: v
      })
    }} />

    <TextInput id="directoryUrl" errors={errors} label="Repertoire Distant" value={phoneConfigInput?.directoryUrl || ""} onChange={(v) => {
      onChange({
        ...phoneConfigInput,
        directoryUrl: v === "" ? null : v
      })
    }} />
  </>

}

