import { gql } from "@apollo/client"


// ${PhoneConfigFragment}
export default gql`

mutation phoneConfigAdd($input: PhoneConfigEditInput!) {
  phoneConfig: phoneConfigAdd(input: $input) {
    id
    name
    hostingConfigId
    directoryUrl
  }
}
`