import { PhoneDirectoryModule } from "@zipou/phone_front"

type PhoneDirectoryModuleDisplayProps = {
  id: string,
  token: string,
}

export const PhoneDirectoryModuleDisplay = ({ id, token, }: PhoneDirectoryModuleDisplayProps) => {


  const env = process.env.NODE_ENV

  const moduleConfig = env === "development" ? {
    url: "http://localhost:4001",
    wsUrl: "ws://localhost:4001",
  } : {
    url: "https://www.box4b.fr",
    wsUrl: "wss://www.box4b.fr/subscriptions",
  }


  return <div>
    <PhoneDirectoryModule
      phoneConfigId={id}
      token={token || ""}
      moduleConfig={moduleConfig}
    />
  </div>

}