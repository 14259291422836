import { useState, useContext } from "react"
import { useQuery, useMutation } from "@apollo/client"

import { ClusterForm } from "../Form/ClusterForm"

import { userContext } from "context/User"

import clusterGet from "graphql/HOSTING/Cluster/Cluster/query/clusterGet"
import clusterUpdate from "graphql/HOSTING/Cluster/Cluster/mutation/clusterUpdate"
import { IconLoading } from "@zipou/front_tools"
import { Cluster, ClusterInput } from "model"

type ClusterEditProps = {
  id: string,
  open?: boolean,
  onDone?: () => void,
}


export const ClusterEdit = ({ onDone, open, id }: ClusterEditProps) => {

  const [displayPassword, updateDisplayPassword] = useState(false)
  const [success, updateSuccess] = useState(false)
  const [isUpdated, updateIsUpdated] = useState(false)
  const [isLocked, updateIsLocked] = useState(false)
  const [cluster, updateCluster] = useState<ClusterInput | null>(null)
  const [error, updateError] = useState<any>(null)
  const user: any = useContext(userContext)

  const [mutate, { loading }] = useMutation(clusterUpdate)

  const { data } = useQuery<{ cluster: Cluster }>(clusterGet, {
    variables: {
      id,
    },
    onCompleted: (data) => {
      updateCluster(data?.cluster as ClusterInput)
    }
  })

  const onReset = () => {
    updateIsUpdated(false)
    updateCluster(data?.cluster as ClusterInput)
  }

  const onSubmit = () => {
    updateIsLocked(true)
    const input: ClusterInput = {
      name: cluster?.name || "",
      passwordUpdate: cluster?.passwordUpdate,
      ClusterConfig: cluster?.ClusterConfig?.map((clusterConfig) => {
        const { id, ClusterRoleConfig, serverId, name } = clusterConfig
        return {
          id,
          name,
          serverId,
          ClusterRoleConfig: ClusterRoleConfig?.map((roleConfigItem) => {
            const { id, clusterRoleId, name, options, version, status, toDelete, port } = roleConfigItem
            return {
              id,
              clusterRoleId,
              name,
              version,
              status,
              options,
              toDelete,
              port,
            }
          })
        }
      })
    }

    mutate({
      variables: {
        id,
        input: input
      }
    })
      .then(response => {
        onDone && onDone()
        updateSuccess(true)
        updateIsUpdated(false)
        updateIsLocked(false)
        updateCluster(response?.data?.cluster)

      })
      .catch(() => {
        console.log("EERRRREUR")
        updateError(true)
        updateIsLocked(false)
      })
  }

  const onChange = (cluster: any) => {
    updateIsUpdated(true)
    updateCluster(cluster)
  }


  return <div className="cluster-edit-container">
    {error && <div className="alert alert-danger">
      Erreur...
    </div>}
    {success && <div className="alert alert-success">
      OK :)
    </div>}
    <h1>{cluster?.name}</h1>
    <h2>Informations</h2>
    <div className="">
      <table className="table table-bordered table-stripped">
        <tbody>
          <tr>
            <th style={{ width: 250 }}>Utilisateur</th>
            <td>{data?.cluster?.user}</td>
          </tr>
          <tr>
            <th>Mot de passe</th>
            <td>{(displayPassword && data?.cluster?.password) || <button className="btn btn-dark btn-sm" onClick={() => updateDisplayPassword(true)}>Afficher le mot de passe</button>}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div className="content">
      <ClusterForm cluster={cluster as Cluster} onChange={onChange} />
    </div>
    <div className="actions pt-5">
      <button className="btn btn-dark btn-sm" onClick={onSubmit} disabled={isLocked}>{loading && <span className="mr-2"><IconLoading /></span>}Enregistrer</button>
      {isUpdated && <button className="btn btn-danger" onClick={onReset}>Annuler</button>}
    </div>

  </div>

}