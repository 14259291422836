import { gql } from "@apollo/client"

//  ${PhoneConfigFragment}

export default gql`

  query phoneConfigGet($id: String!) {
    phoneConfig: phoneConfigGet(id: $id) {
      id
      name
      directoryUrl
      hostingConfigId
    }
  }
`