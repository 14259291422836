import { useState } from "react"
import { PhoneConfigForm } from "../Form/PhoneConfigForm"
import { PhoneConfig, PhoneConfigEditInput, PhoneConfigInput } from "model"
import { useMutation, useQuery } from "@apollo/client"
import phoneConfigGetGql from "graphql/HOSTING/Phone/Config/query/phoneConfigGet.gql"
import phoneConfigEdit from "graphql/HOSTING/Phone/Config/mutation/phoneConfigEdit"

type PhoneConfigEditProps = {
  id: string,
  onDone?: () => void,
}

export const PhoneConfigEdit = ({ id, onDone }: PhoneConfigEditProps) => {

  const [phoneConfig, updatePhoneConfig] = useState<PhoneConfigEditInput>()
  const [errors, updateErrors] = useState<any>()

  const { data } = useQuery<{ phoneConfig: PhoneConfig }>(phoneConfigGetGql, {
    variables: {
      id,
    },
    skip: !id,
    onCompleted: (data) => {
      updatePhoneConfig(getInput(data.phoneConfig))
    }
  })

  const getInput = (phoneConfig: PhoneConfig): PhoneConfigEditInput => {
    return {
      name: phoneConfig?.name,
      hostingConfigId: phoneConfig?.hostingConfigId,
      directoryUrl: phoneConfig?.directoryUrl,
    }
  }

  const [mutate] = useMutation(phoneConfigEdit)

  const onSave = () => {
    mutate({
      variables: {
        id,
        input: phoneConfig,
      }
    })
      .then(() => {
        onDone && onDone()
        console.log("OK")
      })
      .catch((e) => {
        console.log("EEEEE", e)
      })
  }

  return <div>
    <PhoneConfigForm phoneConfigInput={phoneConfig as PhoneConfigInput} onChange={(pc) => updatePhoneConfig(pc)} errors={errors} />
    <div className="mt-1">
      <button className="btn btn-sm btn-dark ml-1" onClick={() => onSave()}>Enregistrer</button>
      <button className="btn btn-sm btn-danger ml-1" onClick={() => onDone && onDone()}>Annuler</button>
    </div>
  </div>
}